import React, { useState } from 'react';
import { Menu } from 'antd';
import { HomeOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import './layout.css';
import Link from 'antd/es/typography/Link';

const Navbar = () => {



    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
            <div className="container px-4">

                <Link className="navbar-brand text-white" to="/#page-top">INFOBOLSA</Link>
                <div className="collapse navbar-collapse mt-1" id="navbarResponsive">
                    <ul className="navbar-nav mx-auto">
                        {/* <li className="nav-item"><Link className="nav-link" to="/mapas">Mapas</Link></li> */}
                        <li className="nav-item"><Link className="nav-link active" to="/">Hechos Esenciales</Link></li>
                    </ul>
                </div>
                <div>
                    <Link className="navbar-brand text-white" href="http://mi.infobolsa.io/login">Entrar</Link>
                    <Link className="navbar-brand text-white" href="http://mi.infobolsa.io/register">Registrarse</Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
