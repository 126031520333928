import logo from './logo.svg';
import './App.css';
import DataTable from './component/DataTable';
import Navbar from './component/layout/navbar';
import FooterX from './component/layout/footer';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Header from './component/header';
import AdSenseComponent from './component/AdSenseComponent';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Header />

        {/* Anuncio entre el Header y las rutas */}
        {process.env.NODE_ENV === 'production' && (
          <AdSenseComponent adSlot="1234567890" style={{ margin: '20px 0', textAlign: 'center' }} />
        )}

        <div className='relative'>
          <div className='mt-5 container'>
            <Routes>
              <Route path="/" element={<DataTable />} />
              <Route path="/resumen_accion" element={<DataTable />} />
              <Route path="/treemap_data" element={<DataTable />} />
            </Routes>
          </div>
        </div>

        {/* Anuncio antes del footer */}
        {process.env.NODE_ENV === 'production' && (
          <AdSenseComponent adSlot="1122334455" style={{ margin: '20px 0', textAlign: 'center' }} />
        )}

        <FooterX />
      </div>
    </Router>
  );
}

export default App
