import React from "react";
import './layout.css';

function FooterX() {
    return (
        <div className="py-5 bg-dark">
            <div className="container px-4">qawefwert
                <p className="m-0 text-center text-white">
                    <a href="https://twitter.com/infobolsa" target="_blank" className="text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </svg>
                    </a>
                </p>
            </div>
        </div>
    )
}

export default FooterX;