import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const DataTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [sortedInfo, setSortedInfo] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/getDataTableData');
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data: ", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: 'Papel',
            dataIndex: 'papel',
            key: 'papel',
            sorter: (a, b) => a.papel.localeCompare(b.papel),
            sortOrder: sortedInfo.columnKey === 'papel' ? sortedInfo.order : null,
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo_he',
            key: 'tipo_he',
            sorter: (a, b) => a.tipo_he.localeCompare(b.tipo_he),
            sortOrder: sortedInfo.columnKey === 'tipo_he' ? sortedInfo.order : null,
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
            sorter: (a, b) => a.fecha.localeCompare(b.fecha),
            sortOrder: sortedInfo.columnKey === 'fecha' ? sortedInfo.order : null,
        },
        {
            title: 'Hora',
            dataIndex: 'hora',
            key: 'hora',
            sorter: (a, b) => a.hora.localeCompare(b.hora),
            sortOrder: sortedInfo.columnKey === 'hora' ? sortedInfo.order : null,
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (text) => <a href={text}><Button>Ver documento</Button></a>,
        },
    ];

    const onSearch = (value) => {
        setSearchText(value);
    };

    const filteredData = data.filter((item) =>
        item.papel.toLowerCase().includes(searchText.toLowerCase()) ||
        item.tipo_he.toLowerCase().includes(searchText.toLowerCase()) ||
        item.fecha.toLowerCase().includes(searchText.toLowerCase()) ||
        item.hora.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className='relative'>
            <div>
                <h1 class="fw-bolder">Hechos Esenciales</h1>
                

                <Space style={{ marginBottom: 16, marginLeft: '80%' }}>
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        value={searchText}
                        onChange={(e) => onSearch(e.target.value)}
                    />
                </Space>

                <Table
                    columns={columns}
                    dataSource={filteredData}
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.key}
                />
            </div>
        </div>
    );
};

export default DataTable;
