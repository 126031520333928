import React, { useEffect } from 'react';

const AdSenseComponent = ({ adSlot, style = {}, format = "auto" }) => {
  useEffect(() => {
    // Solo carga el script de Google AdSense en producción
    if (process.env.NODE_ENV === 'production') {
      const scriptId = 'adsbygoogle-script';

      // Verifica si el script ya está incluido
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2031066491890498';
        script.async = true;
        script.setAttribute('crossorigin', 'anonymous');
        script.id = scriptId;
        document.head.appendChild(script);
      }

      // Inicializa AdSense una vez que el script ha sido añadido
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block', ...style }}  // Estilo del anuncio
      data-ad-client="ca-pub-2031066491890498"  // ID de cliente AdSense
      data-ad-slot={adSlot}  // Slot del anuncio
      data-ad-format={format}  // Formato del anuncio
    ></ins>
  );
};

export default AdSenseComponent;